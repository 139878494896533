
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'
  import SupervisorInspectors from './Team/Inspectors.vue'
  import SupervisorStaff from './Team/Staff.vue'

@Component
  export default class SupervisorTeam extends SupervisorDashboard {
    selectedTab: number = 0
    tabs = [
      {
        name: 'Ejecutivos',
        icon: 'mdi-account',
        component: SupervisorStaff,
      },
      {
        name: 'Inspectores',
        icon: 'mdi-hammer-screwdriver',
        component: SupervisorInspectors,
      },
    ]

    // Methods
    beforeCreate () {
      this.getData = () => {
      }
    }

  // Getters
  get tabComponent () {
    const { selectedTab, tabs } = this
    return tabs[selectedTab].component
  }

  // Watchers
  }
